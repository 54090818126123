<template>
  <div class="edit-personal-info__wrapper">
    <div class="container profile-inner">
      <div class="row">
        <div class="col-12">
          <a @click="handleGoBack" class="backLink">
            <img
              src="../../../../../assets/images/arrow-back-black.svg"
              class="me-3"
            />

            {{ $t("talentWizard.backToProfile") }}
          </a>
        </div>

        <div class="profile-box col-12">
          <div class="mb-4 row">
            <h3 class="mb-4">
              {{ $t("talentWizard.personalInfo") }}
            </h3>

            <div class="mb-4 col-sm-6 col-12">
              <Input
                :label="$t('signUp.firstName')"
                :placeholder="$t('signUp.firstName')"
                :validationField="$v.formData.first_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
                isAstrik="true"
              />
            </div>
            <div class="mb-4 col-sm-6 col-12">
              <Input
                :label="$t('signUp.lastName')"
                :placeholder="$t('signUp.lastName')"
                :validationField="$v.formData.last_name"
                :params="[
                  'required',
                  'alphaNumericWithSpecialChars',
                  { maxLength: { number: '50' } },
                ]"
                isAstrik="true"
              />
            </div>
            <div class="mb-4 col-12">
              <label class="form-label required">{{ $t("signUp.country") }}</label>
              <!-- <country-select
                v-model="$v.formData.country.$model"
                :country="$v.formData.country.$model"
                countryName
                removePlaceholder
                class="px-2 country-select w-100"
              /> -->
              <SelectCountry
                :placeholder="$t('signUp.country')"
                :validationField="$v.formData.country"
                hideLabel="true"
                :selectOptions="countryList"
                keySelected="defaultCountryName"
              />
            </div>
            <hr class="my-4" />
            <h3 class="mb-4">
              {{ $t("talentWizard.roleDetails") }}
            </h3>
            <div class="mb-4 col-12">
              <label class="mb-1 form-label required">
                {{ $t("talentWizard.whatIsYourCurrentRole") }}
              </label>
              <!-- <v-select
                v-model="$v.formData.talent_role_id.$model"
                class="style-chooser"
                placeholder="Current Role"
                :options="rolesOptions"
                label="name"
                :selectable="(option) => !option.hasOwnProperty('group')"
                :reduce="(name) => name.id"
                @input="handleTalentRoleChange"
              >
                <template #option="{ group, name }">
                  <div v-if="group" class="group">{{ group }}</div>
                  {{ name }}
                </template>
              </v-select> -->
              <TalentRolesDDL
                :validationField="$v.formData.talent_role_id"
                hideLabel="true"
                inputStyle="country-select px-2"
              />
              <div class="error-select-validation-message">
                <VulidateError
                  :validationField="$v.formData.talent_role_id"
                  :params="[]"
                />
              </div>
            </div>

            <div v-if="isBackend() || isFrontend()" class="mb-4 col-md-12">
              <label class="mb-1 form-label required">
                {{ $t("talentWizard.selectLanguage") }}
              </label>
              <Select
                :placeholder="$t('talentWizard.selectLanguage')"
                :validationField="roleLangKey()"
                hideLabel="true"
                :selectOptions="isBackend() ? BEOptions : FEOptions"
                @handleChange="(e) => handleRoleLanguageChange(e)"
              />
            </div>
            <div class="mb-4 col-md-6" v-if="isFullStack()">
              <label class="mb-1 form-label required"
                >B.E {{ $t("sharebleLink.language") }}
                {{ $t("talentWizard.selectLanguage") }}
              </label>
              <Select
                :placeholder="'B.E ' + $t('sharebleLink.language')"
                :validationField="$v.formData.BE_lang"
                hideLabel="true"
                :selectOptions="BEOptions"
              />
            </div>
            <div class="mb-4 col-md-6" v-if="isFullStack()">
              <label class="mb-1 form-label required"
                >F.E {{ $t("sharebleLink.language") }}</label
              >
              <Select
                :placeholder="'F.E ' + $t('sharebleLink.language')"
                :validationField="$v.formData.FE_lang"
                hideLabel="true"
                :selectOptions="FEOptions"
              />
            </div>

            <label class="mb-3 required">
              {{ $t("talentWizard.howManyYearsOfExperienceDoYouHave") }}
            </label>
            <div class="col-12" v-if="!isFullStack()">
              <div class="row">
                <div class="mb-3 col-md-12 mb-lg-0 mb-sm-3">
                  <input
                    v-model="$v.formData.experience_years.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.enterYearsOfExperience')"
                  />
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.experience_years"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '50' } },
                      ]"
                    />
                  </div>
                </div>
                <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3" v-if="false">
                  <input
                    v-model="$v.formData.experience_months.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.enterMonthOfExperience')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.experience_months"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '11' } },
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div v-if="isFullStack()" class="col-12">
              <div class="row">
                <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
                  <input
                    v-model="$v.formData.experience_years.$model"
                    type="text"
                    class="form-control"
                    placeholder="For Backend"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.experience_years"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '50' } },
                      ]"
                    />
                  </div>
                </div>
                <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3" v-if="false">
                  <input
                    v-model="$v.formData.experience_months.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.enterMonthOfExperience')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.experience_months"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '11' } },
                      ]"
                    />
                  </div>
                </div>

                <div v-if="isFullStack()" class="mb-3 col-md-6 mb-lg-0 mb-sm-3">
                  <input
                    v-model="$v.formData.second_experience_years.$model"
                    type="text"
                    class="form-control"
                    placeholder="For Frontend"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.second_experience_years"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '50' } },
                      ]"
                    />
                  </div>
                </div>
                <div class="mb-3 col-md-6 mb-lg-0 mb-sm-3" v-if="false">
                  <input
                    v-model="$v.formData.second_experience_months.$model"
                    type="text"
                    class="form-control"
                    :placeholder="$t('talentWizard.enterMonthOfExperience')"
                  />
                  <!-- ********* Validation Error*********** -->
                  <div class="error-select-validation-message">
                    <VulidateError
                      :validationField="$v.formData.second_experience_months"
                      :params="[
                        'required',
                        'numeric',
                        { minValue: { number: '0' } },
                        { maxValue: { number: '11' } },
                      ]"
                    />
                  </div>
                </div>
              </div>
            </div>

            <hr class="mt-5 mb-4" />

            <div class="gap-2 mb-4 d-flex align-items-center">
              <h3 class="mb-0 me-auto">
                {{ $t("talentWizard.businessAccounts") }}
              </h3>
              <button
                type="button"
                class="btn add-btn-not-dashed float-end purple-btn btnstyleUpdate"
                @click="addLink"
                :disabled="checkNumberOfWebsites()"
              >
                <i class="bi bi-plus-circle"></i>
                <span>{{ $t("talentWizard.addLink") }}</span>
              </button>
            </div>

            <div
              class="mb-3 col-12"
              v-for="(link, index) in linksInputs"
              :key="'link-' + index"
            >
              <h6 class="mb-2">{{ link.label }}</h6>
              <div class="input-group flex-nowrap website-input">
                <span class="input-group-text" id="addon-wrapping">
                  <img
                    :src="require('../../../../../assets/images/' + link.img)"
                    width="30"
                    alt
                  />
                </span>
                <input
                  type="text"
                  class="form-control"
                  v-model="$v.formData.links[link.model].$model"
                  :placeholder="link.placeHolder"
                />
              </div>
              <div class="mt-1">
                <VulidateError
                  :validationField="$v.formData.links[link.model]"
                  :params="['urlFormat']"
                />
              </div>
            </div>
            <div
              class="mb-3 col-12"
              v-for="(website, index) in $v.formData.links.websites.$each.$iter"
              :key="index"
            >
              <h6 class="mb-2">website link</h6>
              <div class="form-group row position-relative website-input">
                <div class="input-group flex-nowrap">
                  <span class="input-group-text" id="addon-wrapping">
                    <img
                      src="../../../../../assets/images/website.svg"
                      width="30"
                      alt
                    />
                  </span>
                  <input
                    v-model="website.url.$model"
                    type="text"
                    class="form-control"
                    placeholder="e.g: www.website/username"
                  />
                  <button
                    type="button"
                    class="btn account-remove-btn"
                    @click="deleteLink(index)"
                  >
                    <img src="../../../../../assets/images/ic_delete.svg" alt />
                  </button>
                </div>
                <div class="mt-1">
                  <VulidateError
                    :validationField="website.url"
                    :params="['urlFormat']"
                  />
                </div>
              </div>
            </div>
            <hr class="mt-3" />
            <div class="mt-3 d-flex justify-content-end">
              <Button
                :text="$t('talentWizard.cancel')"
                varient="btn"
                :action="() => handleGoBack()"
                btnStyle="py-3 px-4 mx-1 Personal info"
              />
              <Button
                :text="$t('talentWizard.save')"
                varient="btnSecondary"
                :action="() => handleSubmitPersonalInfo()"
                btnStyle="py-3 px-5"
                :disabled="validForm()"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "../../../../../components/shared/formControls/input/input.vue";
import VulidateError from "../../../../../components/shared/VulidateError/vulidateError";
import Button from "../../../../../components/shared/button/button.vue";
import Select from "../../../../../components/shared/formControls/select/select.vue";
import ACCOUNTS from "../../../../../enums/UserAccounts";
import {
  nonMandatoryUrlHelperValidation,
  scrollToTop,
  alphaNumericWithSpecialChars,
} from "../../../../../utils/utils";
import "vue-select/dist/vue-select.css";
import "./editPersonallInfo.scss";
import TalentRolesDDL from "../../../../../components/shared/talentRolesDDL/talentRolesDDL.vue";
import SelectCountry from "@/pages/Talent/profile/components/editPersonalInfo/selectCountry.vue";
import {
  required,
  maxLength,
  requiredIf,
  numeric,
  maxValue,
  minValue,
} from "vuelidate/lib/validators";
const urlFormat = nonMandatoryUrlHelperValidation;

export default {
  components: { Input, VulidateError, Button, Select, TalentRolesDDL, SelectCountry},
  mounted() {
    this.getBasicInfoData();
    this.getTalentRoles();
    this.$store.dispatch("shared/getCountriesRequest");
    this.getFrontendOptions();
    this.getBackendOptions();
  },
  data: () => {
    return {
      rolesOptions: [],
      retrievedRoleId: null,
      defaultCountryCode: "SA",
      formData: {
        experience_years: "",
        second_experience_years: "",
        experience_months: "",
        second_experience_months: "",
        first_name: "",
        last_name: "",
        country: "",
        talent_role_id: "",
        BE_lang: "",
        FE_lang: "",
        links: {
          linkedIn: "",
          gitHub: "",
          behance: "",
          dribbble: "",
          websites: [{ url: "" }],
        },
      },

      BEOptions: [],
      FEOptions: [],
      linksInputs: [
        {
          label: "Linkedin link",
          placeHolder: "e.g: www.linkedin/username",
          img: "ic-linkedin.svg",
          model: "linkedIn",
        },
        {
          label: "Dribbble link",
          placeHolder: "e.g: www.dribbble/username",
          img: "dribbble.svg",
          model: "dribbble",
        },
        {
          label: "Behance link",
          placeHolder: "e.g: www.behance/username",
          img: "behance.svg",
          model: "behance",
        },
        {
          label: "Github link",
          placeHolder: "e.g: www.Github/username",
          img: "Github.svg",
          model: "gitHub",
        },
      ],
    };
  },
  computed: {
    console: () => console,
    countryList() {
      return this.$store.getters["shared/getCountryList"].map((country) => ({
        ...country,
        defaultCountryName: country.name,
        name: this.$t(
          `countries.${[country.name.replaceAll(" ", "").replaceAll(".", "-")]}`
        ),
      }));
    },
  },
  validations: {
    formData: {
      experience_years: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      second_experience_years: {
        required: requiredIf(function () {
          return this.isFullStack();
        }),
        minValue: minValue(0),
        maxValue: maxValue(50),
        numeric,
      },
      experience_months: {
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      second_experience_months: {
        minValue: minValue(0),
        maxValue: maxValue(11),
        numeric,
      },
      country: {},
      first_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      last_name: {
        required,
        maxLength: maxLength(100),
        alphaNumericWithSpecialChars,
      },
      talent_role_id: { required },
      BE_lang: {
        required: requiredIf(function () {
          return this.isBackend() || this.isFullStack();
        }),
      },
      FE_lang: {
        required: requiredIf(function () {
          return this.isFrontend() || this.isFullStack();
        }),
      },
      links: {
        linkedIn: { urlFormat },
        gitHub: { urlFormat },
        behance: { urlFormat },
        dribbble: { urlFormat },
        websites: {
          $each: { url: { urlFormat } },
        },
      },
    },
  },
  watch: {
    "formData.country": {
      handler(newVal) {
        let countrySelected = this.countryList?.find(
          (ele) => ele.name === newVal
        );
        this.defaultCountryCode = countrySelected?.code;
      },
      deep: true,
    },
  },
  methods: {
    scrollToTop,
    roleLangKey() {
      if (this.isBackend()) {
        return this.$v.formData.BE_lang;
      } else if (this.isFrontend()) {
        return this.$v.formData.FE_lang;
      }
      return "";
    },
    handleRoleLanguageChange(val) {
      if (this.isBackend()) {
        this.$v.formData.FE_lang = "";
        this.$v.formData.BE_lang = val;
      } else if (this.isFrontend()) {
        this.$v.formData.BE_lang = "";
        this.$v.formData.FE_lang = val;
      }
    },
    addLink() {
      this.formData.links.websites.push({ url: "" });
    },
    checkNumberOfWebsites() {
      return this.formData.links.websites.length == 10;
    },
    deleteLink(index) {
      this.formData.links.websites.splice(index, 1);
    },
    async getTalentRoles() {
      try {
        await this.axios.get("/api/staticContent/roles").then((res) => {
          this.rolesOptions = res.data;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getFrontendOptions() {
      try {
        await this.axios
          .get("/api/staticContent/frontendLanguages")
          .then((res) => {
            this.FEOptions = res.data.data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    async getBackendOptions() {
      try {
        await this.axios
          .get("/api/staticContent/backendLanguages")
          .then((res) => {
            this.BEOptions = res.data.data;
          });
      } catch (error) {
        console.log(error);
      }
    },
    isBackend() {
      return (
        this.formData.talent_role_id == 1 ||
        this.formData.talent_role_id.id == 1
      );
    },
    isFrontend() {
      return (
        this.formData.talent_role_id == 2 ||
        this.formData.talent_role_id.id == 2
      );
    },
    isFullStack() {
      return (
        this.formData.talent_role_id == 3 ||
        this.formData.talent_role_id.id == 3
      );
    },
    handleTalentRoleChange() {
      if (this.retrievedRoleId == 3 || this.isFullStack()) {
        this.formData.experience_years = 0;
        this.formData.second_experience_years = 0;
      }
    },
    getBasicInfoData() {
      this.$store.dispatch("talentProfile/getBasicInfoRequest").then((res) => {
        let data = res.data.data;
        this.retrievedRoleId = data.role.id;
        this.formData.experience_years = data.experience_years;
        this.formData.second_experience_years = data.second_experience_years;
        this.formData.experience_months = data.experience_months || 0;
        this.formData.second_experience_months = data.second_experience_months || 0;
        this.formData.talent_role_id = data.role.id;
        // this.formData.first_name = data.first_name.replace(/[^a-zA-Z]/g, '');
        // this.formData.last_name = data.last_name.replace(/[^a-zA-Z]/g, '');
        this.formData.first_name = data.first_name;
        this.formData.last_name = data.last_name;

        this.formData.country = data.country

       /*  this.$t(
          `countries.${[data.country.replaceAll(" ", "").replaceAll(".", "-")]}`
        ); */

         let countrySelected = this.countryList?.find(
          (ele) => ele.defaultCountryName === data.country
        );
        this.defaultCountryCode = countrySelected?.code;

        this.formData.BE_lang = data.BE_lang;
        this.formData.FE_lang = data.FE_lang;
        this.validForm();

        data.accounts.forEach((account) => {
          if (account.type != ACCOUNTS.WEBSITE) {
            let receivedLinks = {
              [ACCOUNTS.LINKED_IN]: "linkedIn",
              [ACCOUNTS.GITHUB]: "gitHub",
              [ACCOUNTS.BEHANCE]: "behance",
              [ACCOUNTS.DRIBBBLE]: "dribbble",
            };
            this.formData.links[receivedLinks[account.type]] = account.url;
          } else {
            this.formData.links.websites[0].url != ""
              ? this.formData.links.websites.push({ url: account.url })
              : (this.formData.links.websites[0].url = account.url);
          }
        });

        setTimeout(() => {
          this.$v.$touch();
        });
      });
    },
    validForm() {
      return this.$v.$invalid;
    },
    handleGoBack() {
      this.scrollToTop();
      this.$router.push({ path: "/sq/talent/profile" });
    },
    handleSubmitPersonalInfo() {
      const websiteArr = [];
      this.formData.links.websites.map(
        (data) => data.url !== "" && websiteArr.push(data.url)
      );
     
      const data = {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        country:  this.formData.country.name?  this.formData.country.name : this.formData.country,
        role_id: this.formData.talent_role_id,
        be_lang_id:
          typeof this.formData.BE_lang == "number"
            ? this.formData.BE_lang
            : this.formData.BE_lang?.id,
        fe_lang_id:
          typeof this.formData.FE_lang == "number"
            ? this.formData.FE_lang
            : this.formData.FE_lang?.id,
        linkedIn: this.formData.links.linkedIn,
        gitHub: this.formData.links.gitHub,
        behance: this.formData.links.behance,
        dribbble: this.formData.links.dribbble,
        websites: websiteArr,
        experience_years: this.formData.experience_years,
        second_experience_years:
          this.formData.talent_role_id == 3
            ? this.formData.second_experience_years
            : "",
        experience_months: this.formData.experience_months,
        second_experience_months:
          this.formData.talent_role_id == 3
            ? this.formData.second_experience_months
            : "",
      };

      this.$store
        .dispatch("talentProfile/EditPersonalInfoRequest", data)
        .then(() => {
          // this.handleGoBack()
          window.location.assign("/sq/talent/profile");
          this.scrollToTop();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn.account-remove-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
