<template>
  <div :class="'baseBtn' +' '+ wrapperStyle">
    <button
      :class="'btn btnstyleUpdate' +' '+ varient +' '+ btnStyle"
      :type="type"
      @click="action"
      :disabled="disabled"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
import "./button.scss";
export default {
  name: "Button",
  props: {
    btnStyle: {
      type: String,
      default: "",
    },
    wrapperStyle: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    action: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: "button",
    },
    varient: {
      type: String,
      default: "btnPrimary",
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped lang="scss"></style>